import { cloneDeep, isNil } from 'lodash';
import { AnyAction } from 'redux';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { errorActions, portfolioActions, searchSecuritiesActions } from '../actions';
import { createFilterActions } from '../actions/filter.actions';
import { portfolioListActions } from '../actions/portfolio-list.actions';
import { pushDataActions } from '../constants';
import { dateRangeFilterOptions } from '../constants/date-range.filter';
import { RequestState } from '../constants/request-state';
import { portfolioService } from '../services';
import { BwicFilterType, FilterState, TFilter } from '../types/filters/FilterState';
import { PageConfigType } from '../types/page-config/PageConfigType';
import { Portfolio } from '../types/portfolio/Portfolio';
import { AppState } from '../types/state/AppState';
import { UserConfigFilter } from '../types/user-config/UserConfigFilter';
import { arrayUtils, isRequestSuccess } from '../utils';
import { queryStringSerializer } from '../utils/filtering';
import { getDateOptionByRange } from '../utils/filtering/filter.utils';
import { QueryStringArgumentSerializer } from '../utils/filtering/serializers/QueryStringArgumentSerializer';
import {
    collateralManagers, collateralTypes, dealsLegalNames, esg, euCompliance, identifiers, mixedDateRange,
    multipleCurrencies, ratings, staticDeals, dateFrom, dateTo, trustees, amr, outOfNC, outOfRI
} from '../utils/filtering/serializers/serializer.definitions';
import { DeserializeCommandHandler } from '../utils/filtering/serializers/DeserializeCommand';
import { user } from '../user/user';
import { SubscriptionFeature } from '../types/billing/SubscriptionFeature';
import { manageEmailPreferencesActions } from '../actions/manage-email-preferences.actions';
import { history } from '../history';

const filterActions = createFilterActions(BwicFilterType.Portfolio);

function* watchRequest() {
    try {
        const portfolios: Portfolio[] = yield call(portfolioService.getPortfolios);
        yield put(portfolioListActions.requestResult(RequestState.success, portfolios));
        yield setDefaultFilter(portfolios);
        yield setDefaultFilterSelect();
    } catch (e) {
        yield put(portfolioListActions.requestResult(RequestState.failure));
        yield put(errorActions.criticalError(e));
    }
}

function* setDefaultFilter(portfolios: Portfolio[]) {
    const filter: TFilter<BwicFilterType.Portfolio> = yield select((s: AppState) => s.filters.portfolio.filter);
    const initialFilter = cloneDeep(filter);

    const getFilterItems = (collection: { legalName?: string, referenceName?: string }[]) => arrayUtils
        .distinct(collection, c => c.legalName)
        .filter(c => !!c.legalName && !!c.referenceName)
        .sort((a, b) => String(a.legalName).localeCompare(String(b.legalName)))
        .map(d => ({ value: d.referenceName, text: d.legalName, selected: false, visible: true, disabled: false }));

    const allDealNames = portfolios.map(p => p.securities.map(s => s.dealLegalName)).flat();
    const dealNameFilterItems = arrayUtils
        .distinct(allDealNames, name => name)
        .filter(name => !!name)
        .sort((a, b) => String(a).localeCompare(String(b)))
        .map(d => ({ value: d, text: d, selected: false, visible: true, disabled: false }));

    const allManagers = portfolios.map(p => p.securities.map(s => ({ legalName: s.collateralManager?.legalName, referenceName: s.collateralManager?.referenceName }))).flat();
    const managerFilterItems = getFilterItems(allManagers);

    const allTrusteeNames = portfolios.map(p => p.securities.map(s => ({ legalName: s.trustee?.legalName, referenceName: s.trustee?.referenceName }))).flat();
    const trusteeFilterItems = getFilterItems(allTrusteeNames);

    initialFilter.dealName = { ...initialFilter.dealName, filter: dealNameFilterItems };
    initialFilter.managers = { ...initialFilter.managers, filter: managerFilterItems };
    initialFilter.trustee = { ...initialFilter.trustee, filter: trusteeFilterItems }

    yield put(filterActions.init(initialFilter));
}

function* setDefaultFilterSelect() {
    const filterQueryString: string = history.location.search;
    const filter: FilterState<BwicFilterType.Portfolio> = yield select((s: AppState) => s.filters.portfolio);

    const queryString = filterQueryString && filterQueryString.trim();

    const filtersConfig: UserConfigFilter[] = yield select((s: AppState) => s.entities.pageConfig.configs?.[PageConfigType.Portfolio]?.filters);
    const canUseAdvancedFilters = user.hasFeatures(SubscriptionFeature.PortfolioAvancedFilters);
    const defaultConfigFilter = canUseAdvancedFilters ? (filtersConfig || []).find(filter => filter.default) : undefined;

    if (queryString?.length) {
        const acceptableCollateralMembers = (filter.filter.managers.filter as { value: string }[]).map(f => f.value);

        const [ncEndSerializers, getNcEndDeserializeResult] = mixedDateRange('nonCallEndFrom', 'nonCallEndTo');
        const [riEndSerializers, getRiEndDeserializeResult] = mixedDateRange('reinvestmentEndFrom', 'reinvestmentEndTo');

        let searchInputValues: string[] = []
        let collateralType: string[] = []
        let currencyValues: string[] = [];
        let ratingValues: string[] = [];
        let dealNames: string[] = [];
        let collateralManager: string[] = [];
        let isEsg: boolean | undefined = undefined;
        let isEuCompliance: boolean | undefined = undefined;
        let isStaticDeal: boolean | undefined = undefined;
        let maturityFrom: Date | null = null;
        let maturityTo: Date | null = null;
        let vintageFrom: Date | null = null;
        let vintageTo: Date | null = null;
        let closingFrom: Date | null = null;
        let closingTo: Date | null = null;
        let trusteeValues: string[] = [];
        let amrValue: boolean | undefined = undefined;
        let isOutofRI: boolean | undefined = undefined;
        let isOutOfNC: boolean | undefined = undefined;

        const serializers: QueryStringArgumentSerializer<any>[] = [
            identifiers(values => searchInputValues = values),
            ratings(values => ratingValues = values),
            multipleCurrencies(values => currencyValues = values),
            dealsLegalNames(values => dealNames = values),
            collateralTypes(values => collateralType = values),
            collateralManagers(values => collateralManager = values, acceptableCollateralMembers),
            esg(value => isEsg = value),
            euCompliance(value => isEuCompliance = value),
            staticDeals(value => isStaticDeal = value),
            outOfNC(value => isOutOfNC = value),
            outOfRI(value => isOutofRI = value),
            ...ncEndSerializers,
            ...riEndSerializers,
            dateFrom(value => maturityFrom = value, 'maturityFrom'),
            dateTo(value => maturityTo = value, 'maturityTo'),
            dateFrom(value => vintageFrom = value, 'vintageFrom'),
            dateTo(value => vintageTo = value, 'vintageTo'),
            dateFrom(value => closingFrom = value, 'closingFrom'),
            dateTo(value => closingTo = value, 'closingTo'),
            trustees(values => trusteeValues = values),
            amr(value => amrValue = value),
        ];
        queryStringSerializer.deserialize(queryString, serializers);
        // Set nonCallEnd and reinvestmentEnd values
        const ncEndDeserializeResult = getNcEndDeserializeResult();
        const riEndDeserializeResult = getRiEndDeserializeResult();

        const searchInputValuesCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !!searchInputValues.length,
            execute: function* () {
                yield all(searchInputValues.map(value => put(searchSecuritiesActions.addSearchItem(value))));
            }
        };
        const collateralTypesCommand = {
            shouldExecute: () => !!collateralType.length,
            execute: function* () {
                yield all(collateralType.map(type => put(filterActions.filterSelectChange(type, 'collateralType'))));
            }
        };
        const currenciesCommand = {
            shouldExecute: () => !!currencyValues.length,
            execute: function* () {
                yield all(currencyValues.map(currency => put(filterActions.filterSelectChange(currency, 'currency'))));
            }
        }

        const collateralManagersReferenceNamesCommand = {
            shouldExecute: () => !!collateralManager.length,
            execute: function* () {
                yield all(collateralManager.map(manager => put(filterActions.filterSelectChange(manager, 'managers'))));
            }
        };
        const ratingCommand = {
            execute: function* () {
                yield all(ratingValues.map(rating => put(filterActions.filterSelectChange(rating, 'ratings'))));
            }
        };
        const dealNamesCommand = {
            shouldExecute: () => !!dealNames.length,
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('dealName'));
                yield all(dealNames.map(name => put(filterActions.filterSelectChange(name, 'dealName'))));
            }
        };
        const esgCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isEsg),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('esg'));
                yield put(filterActions.filterRadioChange(isEsg!, 'esg'));
            }
        };
        const euCompilanceCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isEuCompliance),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('euCompliance'));
                yield put(filterActions.filterRadioChange(isEuCompliance!, 'euCompliance'));
            }
        };
        const staticDealsCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isStaticDeal),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('staticDeal'));
                yield put(filterActions.filterRadioChange(isStaticDeal!, 'staticDeal'));
            }
        };
        const outOfNCCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isOutOfNC),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('outOfNC'));
                yield put(filterActions.filterRadioChange(isOutOfNC!, 'outOfNC'));
            }
        };
        const outofRICommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isOutofRI),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('outOfRI'));
                yield put(filterActions.filterRadioChange(isOutofRI!, 'outOfRI'));
            }
        };
        const nonCallEndCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !!ncEndDeserializeResult?.dates || !!ncEndDeserializeResult?.years,
            execute: function* () {
                yield put(filterActions.filterDateQueryStringDeserialized('nonCallEnd', ncEndDeserializeResult))
            }
        };
        const reinvestmentEndCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !!riEndDeserializeResult?.dates || !!riEndDeserializeResult?.years,
            execute: function* () {
                yield put(filterActions.filterDateQueryStringDeserialized('reinvestmentEnd', riEndDeserializeResult));
            }
        };
        const trusteesValuesCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !!trusteeValues.length,
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('trustee'));
                yield all(trusteeValues.map(name => put(filterActions.filterSelectChange(name, 'trustee'))));
            }
        };
        const maturityCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(maturityFrom) || !isNil(maturityTo),
            execute: function* () {
                yield put(filterActions.makeFilterVisible('maturity'));
                const filterOption = getDateOptionByRange(dateRangeFilterOptions.ThisWeek, maturityFrom, maturityTo);
                yield put(filterActions.filterDateSelectOption(filterOption, 'maturity'));
                yield put(
                    filterActions.filterDateSelectCustomRange({ from: maturityFrom, to: maturityTo }, 'maturity'),
                );
            }
        };
        const vintageCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(vintageFrom) || !isNil(vintageTo),
            execute: function* () {
                yield put(filterActions.makeFilterVisible('vintage'));
                const filterOption = getDateOptionByRange(dateRangeFilterOptions.ThisWeek, vintageFrom, vintageTo);
                yield put(filterActions.filterDateSelectOption(filterOption, 'vintage'));
                yield put(
                    filterActions.filterDateSelectCustomRange({ from: vintageFrom, to: vintageTo }, 'vintage'),
                );
            }
        };
        const closingCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(closingFrom) || !isNil(closingTo),
            execute: function* () {
                yield put(filterActions.makeFilterVisible('closing'));
                const filterOption = getDateOptionByRange(dateRangeFilterOptions.ThisWeek, closingFrom, closingTo);
                yield put(filterActions.filterDateSelectOption(filterOption, 'closing'));
                yield put(
                    filterActions.filterDateSelectCustomRange({ from: closingFrom, to: closingTo }, 'closing'),
                );
            }
        };
        const amrValueCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(amrValue),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('amr'));
                yield put(filterActions.filterRadioChange(amrValue!, 'amr'));
            }
        };

        const isAllCommandsExecuted: boolean =
            yield new DeserializeCommandHandler()
                .addCommand(searchInputValuesCommand)
                .addCommand(collateralTypesCommand)
                .addCommand(currenciesCommand)
                .addCommand(collateralManagersReferenceNamesCommand)
                .addCommand(ratingCommand)
                .addCommand(dealNamesCommand)
                .addCommand(esgCommand)
                .addCommand(euCompilanceCommand)
                .addCommand(staticDealsCommand)
                .addCommand(outOfNCCommand)
                .addCommand(outofRICommand)
                .addCommand(nonCallEndCommand)
                .addCommand(reinvestmentEndCommand)
                .addCommand(trusteesValuesCommand)
                .addCommand(maturityCommand)
                .addCommand(vintageCommand)
                .addCommand(closingCommand)
                .addCommand(amrValueCommand)
                .processGenerator()

        if (!isAllCommandsExecuted) {
            yield put(portfolioActions.advancedFilterBlocked(true));
            return;
        }


    } else if (defaultConfigFilter) {
        yield put(filterActions.selectFilterFromConfig(defaultConfigFilter))
    }
    yield put(filterActions.applyFilter());
}

function* watchColorDistributionSend(action: AnyAction) {
    yield put(portfolioListActions.colorDistributionSend({
        bwicReferenceName: action.bwicReferenceName,
        historyAction: action.historyAction,
        colors: action.colors
    }));
}

function* watchBwicStatusChanged(action: AnyAction) {
    yield put(portfolioListActions.bwicStatusChanged({
        bwicReferenceName: action.bwicReferenceName,
        historyAction: action.historyAction,
        bwicStatus: action.bwicStatus
    }));
}

function* watchPortfolioGlobalAlertsChange(
    action: ActionType<typeof manageEmailPreferencesActions.portfolioGlobalAlertsChange>) {
    const portfolios: Portfolio[] = yield select((s: AppState) => s.entities.portfolios.items);
    const portfoliosRequestState: RequestState = yield select((s: AppState) => s.entities.portfolios.requestState);
    const hasPortfolio = portfolios?.length > 0;
    if (hasPortfolio && isRequestSuccess(portfoliosRequestState)) {
        const alertState = portfolios.map(p =>({
            portfolioId: p.id,
            bwicAlert: action.payload.bwicGlovalAlertEnabled,
            dealersInventoryAlert: action.payload.inventoryGlobalAlertEnabled,
            issuanceMonitorAlert: action.payload.issuanceMonitorAlertEnabled,
            rollerDeadlineAlert: action.payload.roolerDeadlineAlertEnabled
        }));

        yield put(portfolioListActions.updateSendAlertState(alertState));
    }
}

export function* watchPortfolioList() {
    yield takeLatest(getType(portfolioListActions.request), watchRequest);
    yield takeEvery(pushDataActions.PUSH_DATA_PUBLIC_COLORS, watchColorDistributionSend);
    yield takeEvery(pushDataActions.PUSH_DATA_BWIC_STATUS_CHANGE, watchBwicStatusChanged);
    yield takeEvery(manageEmailPreferencesActions.portfolioGlobalAlertsChange, watchPortfolioGlobalAlertsChange);
}
